.form3 {
    min-height: 100vh;
    // overflow-y: hidden;

    .form3-container {
        background-image: url('../img/img_backgound.webp');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 700px;

        header {
            padding-top: 15px;
            height: 100px;
            text-align: center;
            img {
                max-width: 100%;
            }
        }

        main {
            padding-top: 15px;
            color: white;
            position: relative;

            h1 {
                text-align: center;
                font: normal normal bold 40px/50px 'Montserrat';
                width: 50%;
                margin: 0 auto;
            }

            .container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50%;
                margin: 50px 0 0;

                h2 {
                    font: normal normal bold 30px/40px 'Montserrat';
                    text-align: center;
                }

                p, span {
                    font: normal normal 500 20px/40px 'Montserrat';
                }
                                
                .pictos {
                    margin: 25px 0;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: center;
                    
                    flex-direction: column;

                     div {
                        margin: 4px 0;
                        display: flex;
                        align-items: center;
                        width: 475px;
                        padding-left: 30px;

                        span {
                            margin: 0 5px;
                        }
                    }
                }
            }

            .form {
                position: absolute;
                right: 15%;
                top: 150px;
                background-color: white;
                color: black;
                padding: 45px;
                border-radius: 2px;
                box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
                width: 30%;
                display: flex;
                flex-direction: column;

                form {
                    display: flex;
                    flex-direction: column;

                    .flex {
                        display: flex;
    
                        div {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            margin: 5px 0;
    
                            input, select, label {
                                margin: 2px 10px;
                            }
    
                            label {
                                font: normal normal 500 23px/35px 'Montserrat';
                            }

                            input, select {
                                border: 1px solid #CCCCCC;
                                padding: 7px 5px;
                            }
    
                        }
                        
                    }

                    .error {
                        color: #E82A2A;
                        align-self: center;
                        margin-top: 10px;
                    }
    
                    button {
                        background-color: #264A6E;
                        color: white;
                        border: none;
                        border-radius: 5px;
                        padding: 10px 0;
                        margin: 10px 0;
                        width: 65%;
                        align-self: center;
                        font: normal normal 600 20px/30px 'Montserrat';
                        cursor: pointer;

                        &:hover {
                            transform: scale(1.02);
                        }
                    }

                }

                h2 {
                    font: normal normal 600 30px/30px 'Montserrat';
                    text-align: center;
                }

                .text {
                    padding: 25px 0;
                    font: normal normal 500 20px/30px 'Montserrat';
                }

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-self: center;
                    background-color: #60B24A;
                    width: 50%;
                    padding: 10px 0;

                    span {
                        margin: 0 5px;
                        color: white;
                    }
                }
        
            }
        }
    }

    footer {
        height: 20vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        padding-top: 50px;
        text-align: center;

        h2 {
            color: #264A6E;
            font: normal normal 500 30px/30px 'Montserrat';
        }

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 60%;
            min-width: fit-content;
            flex-wrap: wrap;
        }
    }
}

.list-check-A{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
        margin: 10px 0;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-left: 5%;

        span {
            margin: 0 5px;
        }
    }
}


.checkbox-container{
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: start;
    margin-left: 10%;

    

p {
     font-size: 12px;
     margin: 0 0 0 6px;
 }

}

@media screen and (max-width: 1536px) {
    .form3 {
        .form3-container {
            height: 564px;
            main {
                .form {
                    right: 7%;
                    width: 40%;
                }

                .container {           
                    .pictos {
                        margin: 0;
                    }
                }

                .form{
                    top: 121px;
                }
            }
        }
    }
}


@media screen and (max-width: 1300px) {
    .form3 {
        .form3-container {
            main {
                .form {
                    right: 2%;
                    width: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 1050px) {
    .form3 {
        .form3-container {
            height: 800px;
                main {
                    h1 {
                    text-align: center;
                    font: normal normal bold 34px/40px 'Montserrat';
                    width: 80%;
                    }

              
                .container {
                    width: 100%;
                    margin-top: 30px;

                    .pictos {
                        margin-top: 30px;
                        width: 100%;

                          div {
                             margin: 10px 0;
                             padding-left: 10px;
                             width: 60%;

                                span {
                                margin: 0 0 0 2px;
                                font-size: 24px;
                               }

                                img{
                                width: 18px;
                                height: auto;
                              }
                    
                             }
                        }
                }

                    .form {
                    right: unset;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 575px;
                    width: 95%;

                }
            }
        }

        footer {
            width: 100%;
            place-content: center;
            height: 10vh;
        }
        .footer-nosend {
            margin-top: 350px;
        }
        .footer-send {
            margin-top: 175px;
        }
    }
}

@media screen and (max-width: 930px) {
    .form3 {
        .footer-send {
            margin-top: 200px;
        }
        .form3-container {
                main {
                        h1{
                       width: 80% ;
                       } 
                .container {
                    width: 100%;
                    .pictos {
                      margin-top: 20px;
                      width: 100%;

                         div {
                           margin: 10px 0;
                           padding-left: 20px;
                           width: 66%;

                            span {
                            margin: 0 4px;
                            font-size: 18px;
                           }

                            img{
                            width: 30px;
                            height: auto;
                        }
                
                     }
                 }
            }
    
         }
        
    }
}

.form3{
    footer{
        height: 16vh;
    .footer-nosend {
        margin-top: 290px;
    }
        }
    }
}

@media screen and (max-width: 650px) {
    .form3 {

        h1 {
            text-align: center;
            font: normal normal bold 20px/30px 'Montserrat';
            width: 94%;
        }
        .footer-send {
            margin-top: 250px;
        }
    }
}

@media screen and (max-width: 530px) {
    .form3 {
        .form3-container {
            height: 800px;
            main {
                .form {
                    top: 550px;
                    width: 95vw;
                    padding: 40px 25px;

                        .flex {
                        flex-direction: column;
                        }
                }
            }
        }
        footer{
            height: 2vh;
        .footer-nosend {
            margin-top: 100px;
        }
      }
    }
}

@media screen and (max-width: 515px) {
    .form3 {
        .form3-container {
            height: 900px;
            main {
                .form {
                   top: 650px;

                    h2 {
                        font: normal normal bold 18px/22px 'Montserrat';
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .form3 {

        .form3-container {
          height: 1000px;
        main {
            h1 {
                text-align: center;
                font: normal normal bold 20px/30px 'Montserrat';
                width: 94%;
            }

            .container{
                margin-top: 20px;
                h2 {
                    font: normal normal bold 20px/30px 'Montserrat';
                    }

                .pictos {
                   
                    width: 100%;
                     div {
                         margin: 4px 0;
                         display: flex;
                         align-items: center;
                         width: 100%;
                         
                         span {
                             margin: 0 4px;
                             font-size: 14px;
                         }
                     }
                 }
            }

            .form{
                top: 500px;
          }
    
        }

        footer {
            height: 14vh;
            
            .footer-nosend {
                 margin-top: 60px;
    
                div{
                width: 100%;
    
                .img{
                    width: 20px;
                }
            }
}
    h2{
    font-size: 20px;
    }
    .footer-send {
                margin-top: 325px;
                 }
              }
           }
       }
}

@media screen and (max-width: 425px) {
    .form3 {
        .form3-container {
            main {
                .form {
                    top: 500px;
                }
            }
        }

        footer {
            height: 2vh;
        .footer-nosend {
        margin-top: 100px;

        div{
            width: 100%;

            .img{
                width: 20px;
            }
        }

    }

        h2{
        font-size: 20px;
        }
        .footer-send {
        margin-top: 200px;
        }
    }
        
   }
}

@media screen and (max-width: 400px) {
    .form3 {
        .form3-container {
            main {
                .form {
                    top: 500px;
                }
            }
        }
        .footer-nosend {
            margin-top: 330px;
        }
        .footer-send {
            margin-top: 450px;
        }
    }
}

@media screen and (max-width: 375px) {
    .form3 {
        .form3-container {
            height: 800px;
            main {
                .form {
                    top: 470px;
                }
            }
        }
       
        footer {
            height: 42vh;
        
        .footer-nosend {
            margin-top: 600px;
          }
        .footer-send {
            margin-top: 475px;
          }
        }
    }
}

